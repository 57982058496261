import React, {ChangeEvent, useEffect} from 'react'
import styled from 'styled-components'
import firebase from './firebase'
import { jsPDF } from "jspdf"

import XLSX from 'xlsx'
import {generateRecap} from './generateRecap'
import {parseData} from './parseData'

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
`

const Block = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.2em 0.6em 0.2em 0.6em;
`

const Info = styled.div<{ isProcessing?: boolean }>`
  border: 0;
  outline: 0;
  font-size: 1em;
  padding: 0.2em 1em;
  display: ${(props) => (props.isProcessing ? 'initial': 'none')};
  background-color: #007e33;
  color: inherit;
  margin-top: 2em;
  border-radius: 0.2em;
`

const Creator = () => {
    const [members, setMembers] = React.useState<Record<string, Member>>({})
    const [selectedMemberID, setSelectedMemberID] = React.useState<string | null>(null)
    const [isProcessingAllMembers, setIsProcessingAllMembers] = React.useState<boolean>(false)

    const loadFile = (file: File) => {
		const reader = new FileReader();
		reader.onload = (e) => {
            if (e.target !== null) {
                /* Parse data */
                const ab = e.target.result;
                const wb = XLSX.read(ab, {type:'array'});
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                if (ws['!ref'] !== undefined) {
                    /* Convert array of arrays */
                    const data = XLSX.utils.sheet_to_json(ws);
                    /* Update state */
                    saveMembers(parseData(data))
                    loadMembers()
                }
            }
		};
		reader.readAsArrayBuffer(file);
	}

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files
        if(files && files[0]) loadFile(files[0])
    }

    const handleClickOnGeneratePDF = () => {
        var doc = new jsPDF()
        if (selectedMemberID !== null && members !== null) {
            doc = generateRecap(doc, members[selectedMemberID])
            doc.save(`${selectedMemberID}.pdf`)
        }
    }

    const handleClickOnGeneratePDF_all = () => {
        if (selectedMemberID !== null && members !== null) {
            var doc = new jsPDF()
            setIsProcessingAllMembers(true)
            
            for (const member of Object.values(members)) {
                doc = generateRecap(doc, member)
                doc.addPage()
            }

            if (doc !== null) {
                doc.save(`recap-adherant.pdf`)
            }
            
            setIsProcessingAllMembers(false)
        }
    }

    const loadMembers = async () => {
        const ref = firebase.database().ref('members')
        const members = ((await ref.once('value')).val() || {}) as Record<string, Member>
        setMembers(members)
    }

    const saveMembers = async (members: Record<string, Member>) => {
        const ref = firebase.database().ref('members')
        await ref.update(members)    
    }

    useEffect(() => {
        loadMembers()
    }, [])

    useEffect(() => {
        if (Object.keys(members).length !== 0) {
            setSelectedMemberID(Object.keys(members)[0])
        }
    }, [members])

    return (
        <MainContainer>
            <Block>
                <h3>Importer des dossiers depuis Assoconnect</h3>
                <div>Veuillez importer ci-dessous un export obtenu à partir d'une campagne d'adhésion</div>
                <input type="file" onChange={handleFileChange}/>
            </Block>
            <Block>
                <h3>Générer un récapitulatif pour un seul patineur</h3>
                <div>Choisissez un patineur :</div>
                <select 
                    onChange={(event) => {setSelectedMemberID(event.target.value)}}
                    value={selectedMemberID !== null ? selectedMemberID : ""}
                >
                    {Object.keys(members).sort().map((memberID) => {
                        return (<option key={memberID} value={memberID}>
                            {memberID}
                        </option>)
                    })}
                </select>
                <button onClick={handleClickOnGeneratePDF}>Générer un PDF</button>
            </Block>
            <Block>
                <h3>Générer un récapitulatif pour tous les patineurs (uniquement dossiers déjà importés)</h3>
                <button onClick={handleClickOnGeneratePDF_all}>Générer un PDF pour tous les patineurs</button>
                <Info isProcessing={isProcessingAllMembers}>{`Fichier en cours de création ... Cette opération peut prendre 2 à 3 minutes...`}</Info>
            </Block>

        </MainContainer>
    )
}

export default Creator