import styled from 'styled-components'

export const Button = styled.button<{ primary?: boolean }>`
  border: 0;
  outline: 0;
  font-size: 1em;
  padding: 0.6em;
  background-color: ${(props) =>
    props.primary || props.type === 'submit' ? '#E72C7B' : '#BEBEBE'};
  text-transform: uppercase;
  letter-spacing: 0.02em;
  color: inherit;
  flex-grow: 1;
  border-radius: 0.3em;
  user-select: none;
  cursor: pointer;
  &:disabled {
    opacity: 0.3;
  }
`