import React from 'react'

import styled from 'styled-components'
import { useAuth } from './useAuth'
import Login from './Login'
import Creator from './Creator'


const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const App: React.FC = () => {
    const {initializing, user} = useAuth()

    return (
        <React.StrictMode>
            <MainContainer>
                {initializing ? (
                    <h2>Chargement...</h2>
                ) : user === null ? (
                    <Login />
                ) : (
                    <Creator />
                )
                }
            </MainContainer>
        </React.StrictMode>
    )
}

export default App