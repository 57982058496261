import { jsPDF } from "jspdf"

const HELVETICA = "helvetica"
const NORMAL = "normal"
const BOLD = "bold"

const ALIGN_LEFT = "left"
const ALIGN_CENTER = "center"

const SPACE_BETWEEN_LINES = 7

const NOT_SPECIFIED = "** Non renseigné **"

function suptitle(doc: jsPDF, text: string) {
    doc.setFontSize(18)
    doc.setFont(HELVETICA, BOLD)
    doc.text(
        text, 
        105, // x
        20, // y
        {align: ALIGN_CENTER}
    )
}

function subtitle(doc: jsPDF, text: string) {
    doc.setFontSize(14)
    doc.setFont(HELVETICA, BOLD)
    doc.text(
        text, 
        105, // x
        30, // y
        {align: ALIGN_CENTER}
    )
}

function paragraph(doc: jsPDF, header: string, lines: string[], x: number, y: number) {
    doc.setFontSize(10)
    doc.setFont(HELVETICA, BOLD)
    doc.text( 
        header, 
        x, // x
        y, // y
        {align: ALIGN_LEFT}
    )

    doc.setFont(HELVETICA, NORMAL)
    for (let i = 0; i < lines.length; i++) {
        doc.text(
            lines[i].replaceAll("null", NOT_SPECIFIED).replaceAll("undefined", NOT_SPECIFIED), 
            x, // x
            y + (i + 1) * SPACE_BETWEEN_LINES, // y
            {align: ALIGN_LEFT}
        )
    }
}

function image(doc: jsPDF, url: string | null) {
    if (url !== null) {
        try {
            const img = new Image()
            img.src = url
            doc.addImage(img, '', 10, 10, 24, 30)
        } catch {
            console.log("Image member can't be loaded")
        }
    }
}


export const generateRecap = (doc: jsPDF, member: Member) => {
    suptitle(doc, "Récapitulatif inscription")
    subtitle(doc, "Saison 2021-2022")

    image(doc, member.imageUrl)

    paragraph(
        doc,
        "Information patineur",
        [
            `Nom : ${member.lastName}`,
            `Prénom : ${member.firstName}`,
            `Date de naissance : ${member.birthDate}`,
            `Lieu de naissance : ${member.birthPlace}`
        ],
        10,
        50
    )

    paragraph(
        doc,
        "Information de facturation",
        [
            `Nom : ${member.buyerLastName}`,
            `Prénom : ${member.buyerFirstName}`,
            `Mail : ${member.buyerEmail}`
        ],
        105,
        50
    )

    paragraph(
        doc,
        "Responsable légal n°1",
        [
            `Nom : ${member.legualGuardian1 ? member.legualGuardian1.lastName : NOT_SPECIFIED}`,
            `Prénom : ${member.legualGuardian1 ? member.legualGuardian1.firstName : NOT_SPECIFIED}`,
            `Téléphone : ${member.legualGuardian1 ? member.legualGuardian1.phone : NOT_SPECIFIED}`,
            `Mail : ${member.legualGuardian1 ? member.legualGuardian1.mail : NOT_SPECIFIED}`
        ],
        10,
        90
    )

    paragraph(
        doc,
        "Responsable légal n°2",
        [
            `Nom : ${member.legualGuardian2 ? member.legualGuardian2.lastName : NOT_SPECIFIED}`,
            `Prénom : ${member.legualGuardian2 ? member.legualGuardian2.firstName : NOT_SPECIFIED}`,
            `Téléphone : ${member.legualGuardian2 ? member.legualGuardian2.phone : NOT_SPECIFIED}`,
            `Mail : ${member.legualGuardian2 ? member.legualGuardian2.mail : NOT_SPECIFIED}`
        ],
        105,
        90
    )

    paragraph(
        doc,
        "Assurance",
        [
            `Garantie de base : ${
                member.baseInsurance === true ? "Oui" : 
                member.baseInsurance === false ? "Non" : 
                NOT_SPECIFIED
            }`
        ],
        10,
        130
    )

    paragraph(
        doc,
        "",
        [
            `Garanties complémentaires : ${
                member.optionalInsurance === true ? "Oui" : 
                member.optionalInsurance === false ? "Non" : 
                NOT_SPECIFIED
            }`
        ],
        105,
        130
    )

    let total_amount
    if (member.baseInsurance !== null && member.totalAmount !== null) {
        if (member.baseInsurance) {
            total_amount = (member.totalAmount).toFixed(2)
        } else {
            total_amount = (member.totalAmount - 0.8).toFixed(2)
        }
    } else {
        total_amount =  "ERREUR"
    }

    paragraph(
        doc,
        "Formule d’adhésion",
        [
            ...member.soldServices,
            `MONTANT TOTAL DÛ : ${total_amount} €`
        ],
        10,
        150
    )
    
    paragraph(
        doc,
        "Autorisation parentale (patineurs mineurs)",
        [
            "Je soussigné M/Mme .............................................. autorise mon fils," +
            " ma fille ..............................................",
            "à pratiquer les disciplines indiquées ci-dessus."
        ],
        10,
        210
    )

    paragraph(
        doc,
        "Autorisations diverses (patineurs mineurs et majeurs)",
        [
            `Droit à l’image : ${
                member.authorizations.imageRights === true ? "Oui" : 
                member.authorizations.imageRights === false ? "Non" : 
                NOT_SPECIFIED
            }`,
            `Encadrement par un entraineur mineur : ${
                member.authorizations.trainedByMinor === true ? "Oui" : 
                member.authorizations.trainedByMinor === false ? "Non" : 
                NOT_SPECIFIED
            }`,
            ``,
            `Signature du responsable légal ou du patineur si majeur`
        ],
        10,
        240
    )

    paragraph(
        doc,
        "",
        [
            `Prise en charge médicale : ${
                member.authorizations.medicalAssistance === true ? "Oui" : 
                member.authorizations.medicalAssistance === false ? "Non" : 
                NOT_SPECIFIED
            }`,
            `Déplacements : ${
                member.authorizations.trip === true ? "Oui" : 
                member.authorizations.trip === false ? "Non" : 
                NOT_SPECIFIED
            }`,
        ],
        105,
        240
    )

    return doc
}