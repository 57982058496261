import React from 'react'
import styled from 'styled-components'

import firebase from './firebase'
import { Button } from './Components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Input = styled.input`
  border: 1;
  border-color: #e72c7b;
  outline: 0;
  min-width: 15em;
  padding: 0.2em 0.6em 0.2em 0.6em;
  background-color: #FFFFFF;
  font-size: 1em;
  margin 0.7em;
  color: inherit;
`

const Banner = styled.div<{ error?: boolean }>`
  border: 0;
  outline: 0;
  font-size: 1em;
  padding: 0.2em 1em;
  background-color: ${(props) => (props.error ? '#CC0000' : '#007e33')};
  color: inherit;
  margin-top: 2em;
  border-radius: 0.2em;
`

const Login = () => {
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState('')

  function submit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault()
    setError('')
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(function (error) {
        setError("Accès refusé")
      })
  }

  return (
    <Container>
      <h1>Identifiez-vous</h1>
      <Form>
        <Input
          type="email"
          placeholder="Votre email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Mot de passe"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button onClick={submit}>Valider</Button>
      </Form>
      {error && <Banner error>{error}</Banner>}
    </Container>
  )
}

export default Login