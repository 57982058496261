import React from 'react'
import firebase from './firebase'

interface State {
  initializing: boolean
  user: firebase.User | null
}

export const useAuth = () => {
  const [state, setState] = React.useState<State>(() => {
    return {
      initializing: true,
      user: null,
    }
  })

  React.useEffect(
    () => {
      firebase
        .auth()
        .getRedirectResult()
        .catch((error: any) => {
          const errorCode = error.code
          const email = error.email
          const errorMessage = error.message
          console.error('Auth redirect error', errorCode, email, errorMessage)
        })
    },
    [
      /* Empty list, run only once */
    ]
  )

  function onChange(user: firebase.User | null) {
    if (user === null) {
      setState({
        initializing: false,
        user,
      })
    } else {
      firebase
        .database()
        .ref(`users/${user.uid}`)
        .update({ email: user.email })
        .catch((e) => {
          console.error("Unable to update user's email", e)
        })


        setState({ initializing: false, user })
    }
  }

  React.useEffect(() => {
    // listen for auth state changes
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange)
    // unsubscribe to the listener when unmounting
    return () => unsubscribe()
  }, [])

  return state
}