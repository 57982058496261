function daysToDateString(days: number | null) {
    if (days !== null) {
        var date = new Date('1900-01-01T00:00:00')
        date.setDate(date.getDate() + (days-2))
        const day = date.getDate().toString().padStart(2, "0")
        const month = (date.getMonth() + 1).toString().padStart(2, "0")
        const year = date.getFullYear()
        return `${day}-${month}-${year}`
    }
    return null
}

function getMemberID(dataRow: any) {
    const lastName = dataRow["Nom participant"].replaceAll(' ', '-')
    const firstName = dataRow["Prénom participant"].replaceAll(' ', '-')
    const transactionID = dataRow["N° de transaction"]
    const id = `${lastName}_${firstName}_${transactionID}`
    return id
}

function getRowProperty(row: Record<string, any>, property: string) {
    if (row[property] !== undefined && row[property] !== 0) {
        return row[property]
    }
    return null
}

function insuranceAnswerToBoolean(answer: string | null) {
    if (answer !== null)
        return answer.includes("Je souhaite y adhérer") || answer.includes("Je souhaite y souscrire")
    return null
}

export const parseData = (data: unknown[]) => {
    var parsedData = {} as Record<string, Member>
    for (let i = 0; i < (data as any).length; i++) {
        const row = data[i] as Record<string, any>
        const memberId = getMemberID(row)
        parsedData[memberId] = {
            lastName: getRowProperty(row, "Nom participant"),
            firstName: getRowProperty(row, "Prénom participant"),
            birthDate: daysToDateString(getRowProperty(row, "Date de naissance")),
            birthPlace: getRowProperty(row, "Lieu de naissance - Ville"),
            buyerFirstName: getRowProperty(row, "Prénom"),
            buyerLastName: getRowProperty(row, "Nom"),
            buyerEmail: getRowProperty(row, "Email acheteur"),
            legualGuardian1: {
                firstName: getRowProperty(row, "Prénom_1"),
                lastName: getRowProperty(row, "Nom_1"),
                phone: getRowProperty(row, "Téléphone_1"),
                mail: getRowProperty(row, "Email"),
            },
            legualGuardian2: {
                firstName: getRowProperty(row, "Prénom_2"),
                lastName: getRowProperty(row, "Nom_2"),
                phone: getRowProperty(row, "Téléphone_2"),
                mail: getRowProperty(row, "Email_1"),
            },
            baseInsurance: insuranceAnswerToBoolean(getRowProperty(row, "Garantie de base individuelle accident FFRS (Recommandé - 0.80€ déjà inclus dans la licence)")),
            optionalInsurance: insuranceAnswerToBoolean(getRowProperty(row, "Garanties complémentaires (Facultatif - Capital décès, capital invalidité et indemnité journalière)")),
            soldServices: getRowProperty(row, "Prestations") ? getRowProperty(row, "Prestations").split('\n') : [],
            totalAmount: row["Montant dû"] !== undefined ? row["Montant dû"] : null,
            authorizations: {
                imageRights: (getRowProperty(row, "Autorisations diverses") || []).includes("Diffuser et utiliser l'image du patineur"),
                trainedByMinor: (getRowProperty(row, "Autorisations diverses") || []).includes("Placer le patineur dans un groupe encadré par un entraîneur mineur"),
                medicalAssistance: (getRowProperty(row, "Autorisations diverses") || []).includes("prendre les mesures nécessaires en cas de blessure"),
                trip: (getRowProperty(row, "Autorisations diverses") || []).includes("Prendre en charge le patineur pour les déplacements")
            },
            imageUrl: getRowProperty(row, "Photo d'identité")
        }
    }
    return parsedData
}