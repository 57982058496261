import firebase from './firebase'

const firebaseConfig = {
  apiKey: "AIzaSyAwGPsJkGkZNZSU9bQWCtYWekdDhjQlnbk",
  authDomain: "recap-creator.firebaseapp.com",
  databaseURL: "https://recap-creator-default-rtdb.firebaseio.com",
  projectId: "recap-creator",
  storageBucket: "recap-creator.appspot.com",
  messagingSenderId: "1049089709805",
  appId: "1:1049089709805:web:3c2ec744f799ac897ddee4"
};

firebase.initializeApp(firebaseConfig);